<template>
  <section class="min-w1600">
    <div class="searchwrap">
       <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span class="firstTitle50">{{ $t('table.head.memType') }}</span></div>
           <select class="reqMember" v-model="reqData.userType">
             <option value="">{{ $t('searchArea.total') }}</option>
             <option value="N">{{ $t('searchArea.user') }}</option>
             <option value="P">{{ $t('searchArea.partner') }}</option>
           </select>
         </div>
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.applyDate') }}</span></div>
           <date-selector :class="'searchbar-content'"
                          @setStartDate="setStartDate"
                          @setEndDate="setEndDate"
                          :startDefault="startDefault"
                          :endDefault="endDefault"
                          :configs="dateConfigs" /><!-- :isOldYn="true" @setOldYn="setOldYn" :oldYn="reqData.oldYn" -->
         </div>
       </div>
       <div class="searchBar flex-jus-bt">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('user.option') }}</span></div>
           <div>
             <select class="mr-5" v-model="selectOption">
              <option value="memId">{{ $t('searchArea.id') }}</option>
              <option value="memNick">{{ $t('searchArea.nick') }}</option>
              <option value="recommenderId">{{ $t('searchArea.highuser') }}</option>
            </select>
            <input type="text" class="mr-5" v-model="searchText" :placeholder="''" />
            <button class="btn-search" type="button" @click="pageSeach">
              <i class="fas fa-search"></i>
            </button>
           </div>
         </div>
         <div class="sumWrap">
           <span>{{ $t('common.sumIn') }}</span>
           <span>{{ numberWithCommas(total) }}</span>
         </div>
       </div>
       <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="cashList.length !== 0">
            <tr v-for="(item, idx) in cashList" :key="idx">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
              </td>
              <td>
                <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">{{ item.memNick }}</button>
              </td>
              <td>
                 <div class="topwrap" v-if="item.topUserList">
                    <select class="select">
                       <template v-for="pt in item.topUserList">
                          <option class="option">
                             <span class="topbox">[{{computedPartnerLevel(pt)}}] </span>
                             <span :class="item.partnerLevel">{{pt.recommenderId}}</span>
                          </option>
                       </template>
                    </select>
                    <i class="icon">+</i>
                 </div>
              </td>
              <td :class="item.partnerLevel">
                 <span class="box">{{computedPartnerLevel(item)}}</span>
              </td>
              <td>
                <span class="money-user roboto">{{ numberWithCommas(item.preCashAmt) }}</span>
              </td>
              <td>
                <span class="money-apply roboto">{{ numberWithCommas(item.cashAmt) }}</span>
              </td>
              <td>
                <span class="money-user roboto">{{ numberWithCommas(Math.abs(item.cashAmt) + Math.abs(item.preCashAmt)) }}</span>
              </td>
              <td style="width: 15%">
                <span class="roboto">{{ item.regDate }}</span>
              </td>
              <td style="width: 15%">
                <span class="roboto">{{ item.updDate }}</span>
              </td>
              <td style="width: 15%">{{ item.memo }}</td>
            </tr>
          </tbody>
          <template v-else>
            <td colspan="10">{{ $t('txt.noData') }}</td>
          </template>
        </table>
      </div>
    </div>
    <pagination v-if="cashList.length !== 0" @goPage="dataRefactor" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHead from '@/components/main/table/Head.vue'
import UiSelect from '@/components/ui/Select'
import UiCheck from '@/components/ui/UiCheckSet'
import { getDateStr, numberWithCommas } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import Memo from '@/components/common/memo'
import { PARTNER_LEVEL } from '@/libs/constants'

import { cashList } from '@/api/member.js'

export default {
  name: 'DepositList',
  components: {
    SearchFilter,
    DateSelector,
    TableHead,
    Pagination,
    Memo
    // UiSelect,
    // UiCheck
  },
  data: function () {
    return {
      total: 0,
      headInfo: {
        fstColumn: false,
        dataList: ['memId', 'memNick', 'upper', 'level', 'befMy', 'cashInAmt', 'aftMy', 'applyDate', 'updDate', 'note']
      },
      srchFiltersProp: {
        selectOptions: [
          {
            useAll: true,
            opts: [
              {
                title: 'id'
              },
              {
                title: 'nick'
              }
            ]
          }
        ]
      },
      searchOptionText: {
        option: 'memId',
        text: ''
      },
      reqData: {
        cashType: 'in',
        cashStatus: 1,
        page: 1,
        memId: '',
        count_per_list: '30',
        startDate: '',
        endData: '',
        oldYn: 'N',
        userType: ''
      },
      pageInfo: {},
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      cashList: [],
      selectOption: 'memId',
      searchText: ''
    }
  },
  methods: {
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    numberWithCommas,
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
        _date.setSeconds(59)
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    pageSeach () {
      this.dataRefactor(1)
    },
    async setOption () {
      this.reqData.memId = ''
      this.reqData.memNick = ''
      this.reqData.recommenderId = ''
      this.reqData[this.selectOption] = this.searchText
    },
    async getCashList (pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      await this.setOption()
      const data = this.reqData
      console.log('req : ', data)
      return await cashList(data)
    },
    async dataRefactor (pageNum) {
      this.emitter.emit('Loading', true)
      const res = await this.getCashList(pageNum)

      this.total = res.data.total.amountSum || 0
      const pageInfo = res.data.pageInfo
      this.pageInfo = pageInfo
      console.log('[RES]getCashList : ', res)
      const cashList = res.data.list
      for (const loop of cashList) {
        loop.balance = '-'
        loop.succDate = '-'
      }

      this.cashList = cashList

      this.emitter.emit('Loading', false)
    }
  },
  created () {
    this.emitter.emit('Loading', true)
    this.setStartDate()
    this.setEndDate()
    if (this.$route.query.id) {
      this.searchText = this.$route.query.id
      this.selectOption = 'memId'
    }
    this.dataRefactor()
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.flex-jus-bt {justify-content: space-between;padding-right: 100px;}
.sumWrap{ display: flex; align-items: center; height: 26px; }
.sumWrap span{ display: flex; height: 100%; padding: 15px 20px; align-items: center; box-sizing: border-box; font-size: 17px; }
.sumWrap span:nth-child(1) {background: #292a2c; color:#fff;border: 1px solid #292a2c;}
.sumWrap span:nth-child(2) {border: 1px solid #292a2c; color: #c17e2a; font-weight: 700;}
</style>
